import React from 'react'
import Layout from "../components/layout"

const ServicesPage = () => {
    return (
        <Layout>
        <div>
            <h1>ServicesPage</h1>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum doloremque consectetur necessitatibus modi sed reprehenderit earum magni. Eaque fuga quaerat atque accusantium delectus placeat rem asperiores error debitis officia. Incidunt!</p>
        </div>
        </Layout>
    )
}

export default ServicesPage;
